<template>
  <div class="donations page">
    <div class="container">
      <h1 class="mb-5">Donations</h1>
      <b-button variant="primary" v-b-modal.modal-add-donation>Add New Donation</b-button>
      <hr>
      <p>
        <strong>Total: {{ getTotal() }}</strong>
      </p>
      <b-table striped bordered hover responsive="true"
        :items="donations" 
        :fields="fields" 
        id="donations-table"
        :per-page="perPage"
        :current-page="currentPage">
        <template slot="createdAt" slot-scope="data">
          <span>{{displayDate(data.item.createdAt)}}</span>
        </template>
        <template slot="action" slot-scope="data">
          <b-badge href="#" @click="onDeleteDonation(data.item)" variant="danger">Delete</b-badge>
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="donations.length"
        :per-page="perPage"
        aria-controls="donations-table"
        v-if="showPagination()"
      ></b-pagination>

      <b-modal id="modal-add-donation" ref="modal-add-donation" centered title="New Donation" :hide-footer="true">
        <b-form @submit="onSubmit">
          <b-form-group
            id="input-group-1"
            label="Full Name:"
            label-for="input-1"
            description="Please enter the full name of the donor"
          >
            <b-form-input
              id="input-1"
              v-model="form.name"
              required
              placeholder="Enter name"
            ></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-2" label="Amount:" label-for="input-2">
            <b-input-group prepend="$" append=".00">
               <b-form-input
                id="input-2"
                v-model="form.amount"
                type="number"
                required
                placeholder="Enter amount"
              ></b-form-input>
            </b-input-group>
          </b-form-group>
          <b-button type="submit" variant="primary">Submit</b-button>
          <b-button class="ml-2" variant="secondary" @click="onCancel">Cancel</b-button>
        </b-form>
      </b-modal>
    </div>
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex';
import moment from "moment";

export default {
  name: 'donation',
  data() {
    return{
      perPage: 10,
      currentPage: 1,
      fields:[
        {
          key: 'name',
          sortable: true
        },
        {
          key: 'amount',
          sortable: true
        },
        {
          key: 'createdAt',
          sortable: true
        },
        {
          key: 'action'
        }
      ],
      form: {
        name: '',
        amount: ''
      }
    }
  },
  computed: mapState([
    'donations'
  ]),
  methods: {
    ...mapActions([
      'addDonation',
      'deleteDonation'
    ]),

    getTotal(){
      let total = 0;
      this.donations.map((donation) => {
        total += donation.amount;
      })
      return total.toLocaleString('en-CA', 
        {style: 'currency', currency: 'CAD'}
      );
    },

    showPagination(){
      return (this.donations.length > this.perPage);
    },

    displayDate(date){
			return moment(date).format('MMMM DD, YYYY - hh:mm:ss a');
    },
    
    makeToast(content, variant = null) {
      this.$bvToast.toast(content, {
        title: "Notification",
        variant: variant,
        solid: true
      })
    },

    onDeleteDonation(data){
      this.$bvModal.msgBoxConfirm('Please confirm that you want to delete this donation.', {
        title: 'Delete Donation',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
        .then(value => {
          if(value){
            this.deleteDonation(data);
            this.makeToast(`Yay!! Donation "${data.name}"  is deleted`, 'success');
          }
        })
        .catch(err => {
          this.makeToast('An error has occurred!', 'danger');
        })
    },

    onSubmit(evt){
      evt.preventDefault();
      this.addDonation(this.form);
      this.$refs['modal-add-donation'].hide();
      this.makeToast('Donation is added successfully!', 'success');
    },

    onCancel(evt){
      evt.preventDefault();
      this.$refs['modal-add-donation'].hide();
    }
  },

  created(){
    this.$store.dispatch('bindDonations');
  }
}
</script>

<style lang="scss">

.table-responsive-true{
  @import "../styles.scss";
  @include breakpoint($bp-md) {
    overflow-x: scroll;
  }
}
</style>